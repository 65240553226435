import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IndexView, SimpleStriped } from 'blocks/tables';
import validationSchema from './validationSchema';
import uploadFileService from 'views/NotFoundCover/uploadFileService';

import FileUploadViewer from 'views/NotFoundCover/FileUploadViewer';

const ThirdStep = ({ handleCurrentStep, thirdState, applicationNo }) => {
  const fileName1 =
    thirdState && thirdState.photoData && thirdState.photoData.name;

  const [fileName, setFileName] = useState(fileName1 || '');

  let isDisabled = true;

  console.log('applicationNo in third step', applicationNo);

  const onSubmit = () => {
    // localStorage.form1Values = JSON.stringify(formik.values);
    handleCurrentStep(4, formik.values);
    if (formik.values.photoBirth) {
      uploadFileService('photoBirth', formik.values.photoBirth);
    }
    if (formik.values.photoAadhar) {
      uploadFileService('photoAadhar', formik.values.photoAadhar);
    }
    if (formik.values.photoFatherAadhar) {
      uploadFileService('aadharFather', formik.values.photoFatherAadhar);
    }
    if (formik.values.photoMotherAadhar) {
      uploadFileService('aadharMother', formik.values.photoMotherAadhar);
    }
  };

  const formik = useFormik({
    initialValues: thirdState,
    validationSchema: validationSchema,
    onSubmit,
  });

  if (formik && formik.values && Object.keys(formik.errors).length === 0) {
    isDisabled = false;
  }

  const handleUpdatePhotoBirthFileInfo = (file, fileName, imagePreviewUrl) => {
    formik.values.photoBirth = file;
    formik.values.photoBirthFileName = fileName;
    formik.values.photoBirthImagePreviewUrl = imagePreviewUrl;
  };

  const handleUpdateAadharFileInfo = (file, fileName, imagePreviewUrl) => {
    formik.values.photoAadhar = file;
    formik.values.photoAadharFileName = fileName;
    formik.values.photoAadharImagePreviewUrl = imagePreviewUrl;
  };

  const handleUpdateFatherAadharFileInfo = (
    file,
    fileName,
    imagePreviewUrl,
  ) => {
    formik.values.photoFatherAadhar = file;
    formik.values.photoFatherAadharFileName = fileName;
    formik.values.photoFatherAadharImagePreviewUrl = imagePreviewUrl;
  };

  const handleUpdateMotherAadharFileInfo = (
    file,
    fileName,
    imagePreviewUrl,
  ) => {
    formik.values.photoMotherAadhar = file;
    formik.values.photoMotherAadharFileName = fileName;
    formik.values.photoMotherAadharImagePreviewUrl = imagePreviewUrl;
  };

  return (
    <Box>
      <div>
        <Typography variant="h4" align="center">
          Upload Documents
        </Typography>
        <br />
        <br />
        <Grid container spacing={4}>
          <Grid item xs={10} sm={6}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              Upload Aadhar Card/Passport of the Student*
            </Typography>

            <div id="aadhar">
              <FileUploadViewer
                key={`key-Aadhar`}
                info={'aadhar'}
                file={formik.values && formik.values.photoAadhar}
                fileName={formik.values && formik.values.photoAadharFileName}
                imagePreviewUrl={
                  formik.values && formik.values.photoAadharImagePreviewUrl
                }
                handleUpdateFileInfo={(file, fileName, imagePreviewUrl) => {
                  console.log('hi hello');
                  handleUpdateAadharFileInfo(file, fileName, imagePreviewUrl);
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              Upload Birth Certificate of the Student*
            </Typography>

            <div id="photoBirth">
              <FileUploadViewer
                key={`key-photoBirth`}
                info={'photoBirth'}
                id="photoBirth"
                file={formik.values && formik.values.photoBirth}
                fileName={formik.values && formik.values.photoBirthFileName}
                imagePreviewUrl={
                  formik.values && formik.values.photoBirthImagePreviewUrl
                }
                handleUpdateFileInfo={(file, fileName, imagePreviewUrl) => {
                  console.log('hi test');
                  handleUpdatePhotoBirthFileInfo(
                    file,
                    fileName,
                    imagePreviewUrl,
                  );
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              Upload Father's Aadhar Card/Passport*
            </Typography>

            <div id="photoFatherAadhar">
              <FileUploadViewer
                key={`key-photoFatherAadhar`}
                info={'photoFatherAadhar'}
                id="photoFatherAadhar"
                file={formik.values && formik.values.photoFatherAadhar}
                fileName={
                  formik.values && formik.values.photoFatherAadharFileName
                }
                imagePreviewUrl={
                  formik.values &&
                  formik.values.photoFatherAadharImagePreviewUrl
                }
                handleUpdateFileInfo={(file, fileName, imagePreviewUrl) => {
                  console.log('hi test');
                  handleUpdateFatherAadharFileInfo(
                    file,
                    fileName,
                    imagePreviewUrl,
                  );
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              Upload Mother's Aadhar Card/Passport*
            </Typography>

            <div id="photoMotherAadhar">
              <FileUploadViewer
                key={`key-photoMotherAadhar`}
                info={'photoMotherAadhar'}
                id="photoMotherAadhar"
                file={formik.values && formik.values.photoMotherAadhar}
                fileName={
                  formik.values && formik.values.photoMotherAadharFileName
                }
                imagePreviewUrl={
                  formik.values &&
                  formik.values.photoMotherAadharImagePreviewUrl
                }
                handleUpdateFileInfo={(file, fileName, imagePreviewUrl) => {
                  console.log('hi test');
                  handleUpdateMotherAadharFileInfo(
                    file,
                    fileName,
                    imagePreviewUrl,
                  );
                }}
              />
            </div>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography
          variant={'subtitle2'}
          sx={{ marginBottom: 2 }}
          fontWeight={700}
        >
          NOTE: Allowed jpeg, jpg and pdf only up to 1MB. Special characters are
          not allowed in file name.
        </Typography>
        <Box
          component={Button}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          height={54}
          //   href='/not-found-cover'
          marginTop={{ xs: 8, sm: 9 }}
          marginLeft={{ sm: 35 }}
        >
          <span onClick={() => handleCurrentStep(2)}>Go Back</span>
        </Box>

        <Box
          component={Button}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          height={54}
          marginTop={{ xs: 8, sm: 9 }}
          marginLeft={{ sm: 4 }}
          disabled={isDisabled}
          readOnly={isDisabled}
          onClick={onSubmit}
          // href = '/startup'
        >
          Save and Continue
        </Box>
      </div>
    </Box>
  );
};

export default ThirdStep;
