import React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import ThirdStep from './ThirdStep';

const Startup = ({handleCurrentStep, thirdState, applicationNo}) => {

  return (

  <ThirdStep handleCurrentStep={handleCurrentStep} 
  thirdState = {thirdState}
  applicationNo={applicationNo}
  />

);
};



export default Startup;
